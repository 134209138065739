:root{
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(135deg, #fcb900, #ff0022 );
  align-items: center; 
  width: 99%;
  


 }

