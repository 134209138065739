p {
  font-family: Arial, Helvetica, sans-serif;
}

form {
  display: flex;
  flex-direction: column;
}

body {
  display: flex;
  flex-direction: column;
  background-color: transparent;
  align-items: center;
  justify-content: center;
  width: 100%;
  
}

table,
th,
td {
  border: 1px solid black;
  width: 80%;
  border-collapse: collapse;
  padding: 10px;
}

input {
  width: 40vh;
  height: 30px;
  border-radius: 12px;
  border: none;
  margin-bottom: 10px;
  outline: none;
  background-color: #ff0022;
}

.hrnew {
  position: relative;
  width: 100%;
  margin-top: 10vh;
}

a {
  color: black;
}
a:hover {
  text-decoration: none;
  cursor: pointer;
}

.header {
  display: inline-flex;
  place-items: center;
  justify-content: space-between;
  position: fixed;
  top: 8px;
  left: 5%;
  margin: auto;
  margin-bottom: none;
  padding: auto;
  background-color: white;
  border-radius: 15px;
  height: 80px;
  width: 90%;
  align-content: center;
}

.logoheader {
  font-size: 50px;
  margin: 20px;
  text-decoration: underline;
  text-align: left;
}

.main {
  display: flex;
  text-align: center;
  font-size: 90px;
  color: #0b303b;
  margin-left: 10%;
  margin-right: 5%;
  margin-top: 18vh;
  margin-bottom: 0vh;
}

.main2 {
  font-size: 16px;
  color: #0b303b;
  align-self: center;
  font-weight: bold;
  opacity: 0.8;
}

.main3 {
  font-size: 20px;
  color: #0b303b;
  margin-top: 150px;
  font-weight: bold;
}

.listoftopproducts {
  display: inline-flex;
  font-size: 36px;
  color: #0b303b;
  opacity: 0.8;
  text-decoration: none;
}

.main5 {
  font-size: 40px;
  color: #0b303b;
  margin-top: 150px;
}

.contacus {
  background-color: #0b303b;
  border-radius: 20px;
  width: 142px;
  height: 50px;
  font-size: 18px;
  font-weight: 550;
  color: white;
  cursor: pointer;
  margin: 12px;
  text-align: center;
  border: none;
}

.contacus:hover {
  opacity: 0.8;
}

.firstboxtitle {
  font-size: 24px;
  font-weight: 100;
  padding-top: 12px;
}

.firstbox {
  font-size: 21px;
  font-weight: 600;
  text-align: center;
  margin: 16px;
}

.boxone {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  width: 40vh;
  height: 300px;
  border-radius: 15px;
  margin: 20px;
}

.boxtwo {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #0b303b;
  width: 40vh;
  height: 320px;
  border-radius: 15px;
  margin: 20px;
  color: white;
}

.boxthree {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #1436ca;
  width: 40vh;
  height: 300px;
  border-radius: 15px;
  margin: 20px;
  color: white;
}

.testimonialbox {
  background-color: #0b303b;
  margin-top: 100px;
  flex-direction: column;
  display: flex;
  width: 90%;
  /* height: 300px; */
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.testimonialsone {
  color: white;
  font-size: 20px;
  font-style: italic;
  text-align: center;
}

.testimonialstwo {
  color: white;
  font-size: 20px;
  text-align: justify;
  padding-left: 5%;
  padding-right: 5%;
}

.whychoose444 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
 
}

.contactbox {
  display: flex;
  flex-direction: column;
  width: 95%;
  background-color: white;
  margin-top: 10vh;
  margin-bottom: 20vh;
  margin-left: 9vh;
  margin-right: 10vh;
  border-radius: 12px;
  align-items: center;
  padding-bottom: 30px;
}

.textone {
  font-size: 30px;
}

.texttwo {
  font-size: 20px;
  font-weight: 800;
}

.footer {
  display: flex;
  margin-left: 20vw;
  text-align: left;
  gap: 50px;
  margin-bottom: 100px;
  flex-wrap: wrap;
  background-color: none;
}

.footerTitle {
  font-weight: 900;
  font-size: large;
  background-color: none;
}

.footercategories {
  font-size: 13px;
  font-weight: bold;
  display: inline-flex;
  flex-direction: column;
  gap: 5px;
  background-color: none;
}

.logofooter {
  font-size: 50px;
  text-decoration: underline;
  text-align: left;
  margin-left: 20vw;
  background-color: none;
}
.logofooter:hover {
  cursor: pointer;
  text-decoration: none;
  background-color: none;
}

.credits {
  margin-left: 20vw;
  text-align: left;
  font-weight: bold;
  font-size: medium;
  margin-bottom: 60px;
}

/* aboutus page css */

.aboutusmain {
  display: flex;
  flex-direction: column;
  place-items: center;
  margin-top: 10vh;
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  min-width: 300px;
  max-width: 600px;
  padding-left: 10px;
  padding-right: 10px;
}

/* termsandconditiontext  */

.termsandconditiontext {
  display: flex;
  flex-direction: column;
  place-items: center;
  margin-top: 10vh;
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  min-width: 300px;
  max-width: 600px;
  padding-left: 10px;
  padding-right: 10px;
}
